.blur-overlay {
    pointer-events: all;
    backdrop-filter: blur(10px);
    background-color: rgba(69, 44, 99, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.25s linear;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.blur-overlay.hidden {
    pointer-events: none;
    backdrop-filter: none;
    opacity: 0;
}

.blur-overlay .loader {
    color: #fff;
    font-size: 25px;
    text-align: center;
    line-height: 32px;
}

.blur-overlay .loader img {
    animation: pulse 0.7s linear infinite;
    width: 100px;
}

.blur-overlay .spiner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: loader-spiner-rotation 1s linear infinite;
}

@keyframes loader-spiner-rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}