@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-Regular.ttf');
}

@font-face {
    font-family: 'Rubik-Medium';
    src: url('./Rubik-Medium.ttf');
}

@font-face {
    font-family: 'Rubik-Light';
    src: url('./Rubik-Light.ttf');
}

@font-face {
    font-family: 'Rubik-Bold';
    src: url('./Rubik-Bold.ttf');
}

@font-face {
    font-family: 'Rubik-Black';
    src: url('./Rubik-Black.ttf');
}

@font-face {
    font-family: 'Rubik-SemiBold';
    src: url('./Rubik-SemiBold.ttf');
}
